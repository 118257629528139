<template>
  <div>
    <b-container fluid="xxl" class="mt-4 mb-4">
      <b-row>
        <b-col>
          <p class="mb-1" v-if="pretitle">{{ pretitle }}</p>
          <h1 v-if="isSafe">{{ title }}</h1>
          <h1 v-else v-html="title"></h1>
          <p v-if="subtitle">{{ subtitle }}</p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  export default {
    name: 'PageTitle',
    props: ['title', 'subtitle', 'pretitle', 'safe'],
    data(){
      return {
        isSafe: this.safe == true
      }
    }
  }
</script>
