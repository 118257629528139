<template>
  <div class="lemma">
    <div v-if="loading || !lemma">
      <b-container fluid="xxl">
        <b-row class="justify-content-md-center">
            <b-col class="mt-5">
              <div class="spinner-border m-5" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-else>
      <b-container fluid="xxl">
        <b-row>
          <b-col class="pl-0">
            <PageTitle v-bind:title="lemmaDisplay" :pretitle="this.$t('LemmaDetails')" />
          </b-col>
          <b-col>
            <!-- <div class="mt-5 text-right">
              <span v-if="editMode">
                <button @click="saveEdit" class="btn-legam-xs mb-2 btn-primary">
                  <b-icon icon="lock-fill"></b-icon>
                  Save changes
                </button>
                <button @click="cancelEdit" class="btn-legam-xs mb-2">
                  Cancel
                </button>
                <button @click="deleteItem" class="btn-legam-xs mb-2 btn-danger">
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
              <span v-else>
                <button @click="editMode = true" class="btn-legam-xs mb-2">
                  <b-icon icon="unlock-fill"></b-icon>
                  Unlock editing
                </button>
              </span>
            </div> -->
          </b-col>
        </b-row>
      </b-container>
      <b-container fluid="xxl">
        <b-row class="justify-content-md-center">
          <b-col v-if="lemma.articleId">
            <router-link :to="{ name: 'Article', params: {id: lemma.articleId}}" class="">
              <b-button size="sm" class="mr-1" variant="light">
                <b-icon icon="book" aria-hidden="true" class="mr-1"></b-icon>
                {{ $t('ArticleDetails') }} (<b>{{ lemma.articleMainLemmaForm }}</b>)
              </b-button>
            </router-link>
          </b-col>
          <!-- <b-col lg="4">
            <LemmaArticleTree v-bind:lemmaId="lemmaId" :key="articleKey" />
          </b-col> -->
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col>
            <LemmaEditDetails :lemmaObj="lemma" :hideTitle="true" @update="updateArticle" :showCloseButton="false" />
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import router from '@/router'
  import PageTitle from '@/components/PageTitle.vue'
  import LemmaEditDetails from '@/components/Lemma/EditDetails.vue'
  // import LemmaArticleTree from '@/components/Lemma/ArticleTree.vue'

  export default {
    name: 'Lemma',
    data() {
      return {
        lemmaId: this.$route.params.id,
        loading: true,
        editMode: false,
        articleKey: 0
      }
    },
    computed: {
      ...mapState({
        lemma: state => state.lemma.lemma
      }),
      lemmaDisplay(){
        let prefix = ''
        if (this.lemma.scriptas.length){
          prefix = `<small>${this.lemma.scriptas.map(scripta => scripta.code).join(', ')} </small>`
        }
        if (this.lemma.isReconstructed){
            prefix = `${prefix}*`
          }
        let sufix = ''
        if (this.lemma.partofspeeches.length){
          sufix = `<small> ${this.lemma.partofspeeches.map(partofspeeche => partofspeeche.name).join(', ')}</small>`
        }
        let etymon = ''
        if (this.lemma.treeParent){
          etymon = `<small> [${this.lemma.treeParent.display}]</small>`
        }
        if (this.lemma.etymon){
          etymon = `<small> [${this.lemma.etymon.display}]</small>`
        }
        return `${prefix}${this.lemma.lemmaForm}${sufix}${etymon}`
      }
    },
    watch: {
      'lemma'() {
        this.loading = false
      }
    },
    mounted(){
      this.$store.dispatch('loadLemma', this.$route.params.id)
    },
    components: {
      PageTitle,
      LemmaEditDetails,
      // LemmaArticleTree
    },
    methods: {
      cancelEdit(){
        this.editMode = false
      },
      saveEdit(){
        this.editMode = false
        this.$store.dispatch('addNotification', {
          type: 'warning',
          text: `Not implemented yet`
        })
      },
      deleteItem(){
        this.$bvModal.msgBoxConfirm('Are you sure?').then(value => {
          if (value){
            this.$store.dispatch('deleteLemma', this.lemma).then(() => {
              router.push('/lemmas')
            })
          }
        })
      },
      updateArticle(){
        this.articleKey++
      }
    }
  }
</script>
